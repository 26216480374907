<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">品种名称</span>
        <el-input v-model="searchParams.name" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (isDisable = false), (dialogVisible = true)">新增品种</el-button>
    </el-row>
    <el-table :data="tableData" style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="title" label="类型">
        <template slot-scope="scope">
          {{ name }}
        </template>
      </el-table-column>
      <el-table-column prop="code" label="品种编号"> </el-table-column>
      <el-table-column prop="name" label="品种名称" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="descriptions" label="描述"> </el-table-column>
      <el-table-column prop="updateTime" label="最近更新时间"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="toEdit(scope.row)">修改</el-button>
          <el-popconfirm title="确认删除吗？" @confirm="toDelete(scope.row)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createSub
      :dialogVisible.sync="dialogVisible"
      :machineType="machineType"
      :isDisabled="isDisable"
      :isEdit="isEdit"
      :currentId="currentId"
      @updateList="getList"
    ></createSub>
  </div>
</template>

<script>
import { data2List, data2Delete } from '@/api/cropCategory/api.js';
import createSub from './createSub.vue';
export default {
  components: {
    createSub
  },
  data() {
    return {
      name: '',
      dicAll: {},
      dialogVisible: false,
      isDisable: false,
      isEdit: false,
      expertName: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      questionType: '',
      searchParams: {
        name: ''
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      activeName: '1',

      tableData: [],
      machineType: [],
      questionTypeDic: null
    };
  },
  createsubd() {},
  async mounted() {
    this.name = this.$route.query.name;
    this.getList();
    this.machineType = await this.$store.dispatch('getDicOptionsList', 'machine.type');
  },
  methods: {
    getMachineType(id) {
      let res = '';
      this.machineType.map((e) => {
        if (e.id == id) {
          res = e.name;
        }
      });
      return res;
    },
    toDelete(item) {
      data2Delete({ id: item.id })
        .then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },

    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },

    toEdit(item) {
      this.isDisable = false;
      this.currentId = item;
      this.isEdit = true;
      this.dialogVisible = true;
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      data2List({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        categoryId: this.$route.query.id,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
