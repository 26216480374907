<!-- //作物产量模型 -->
<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="left-item">
          <div class="header">
            <div class="title">目标产量设置</div>
            <div class="header-right">
              <span>作物</span>
              <el-select v-model="cropValue" @change="cropSelect" size="small" placeholder="请选择">
                <el-option v-for="item in cropList" :key="item.code" :label="item.name" :value="item.code"> </el-option>
              </el-select>
            </div>
          </div>
          <div class="chart-wrap">
            <div ref="outputChart" class="output-chart"></div>
            <div class="setting-btn" @click="openYieldSetting">设置</div>
          </div>
        </div>
        <div class="left-item">
          <div class="header">
            <div class="title">本种植季肥料总投入</div>
            <div class="unit-tip">单位：公斤/亩</div>
          </div>
          <div class="manure-chart-wrap">
            <div ref="manureChart" class="manure-chart"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="12" v-if="baseList.length || addList.length">
        <div class="right" style="height: 300px">
          <el-steps direction="vertical">
            <el-step title="基肥" v-if="baseList.length">
              <template slot="description">
                <div class="step-item" v-for="item in baseList" :key="item.consumption">
                  <div class="label">{{ item.name }}</div>
                  <div class="value">{{ parseFloat(item.consumption || 0).toFixed(2) }}公斤/亩</div>
                </div>
              </template>
            </el-step>
            <template v-if="addList.length">
              <el-step title="追肥">
                <template slot="description">
                  <div class="step-item" v-for="item in addList" :key="item.consumption">
                    <div class="label">{{ item.name }}</div>
                    <div class="value">{{ parseFloat(item.consumption || 0).toFixed(2) }}公斤/亩</div>
                  </div>
                </template>
              </el-step>
              <el-step title=""></el-step>
            </template>
            <el-step v-else title=""></el-step>
          </el-steps>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="提示" :visible.sync="yieldDg" width="30%" center>
      <div class="yield-wrap">
        产量：<el-input type="number" v-model="selectedCrop.value" @change="yieldInput" placeholder="请输入产量"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yieldDg = false">取 消</el-button>
        <el-button type="primary" @click="confirmYield">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { yieldPrediction } from '@/api/soilManage/api.js';
import * as echarts from 'echarts';
export default {
  data() {
    return {
      cropList: [
        {
          code: 1,
          name: '小麦',
          minValue: 200,
          maxValue: 800,
          value: 500
        },
        {
          code: 2,
          name: '玉米',
          minValue: 300,
          maxValue: 1000,
          value: 500
        },
        {
          code: 3,
          name: '大豆',
          minValue: 150,
          maxValue: 500,
          value: 150
        }
      ],
      cropValue: 1,
      selectedCrop: {},
      baseList: [],
      addList: [],
      yieldDg: false
    };
  },
  created() {},
  mounted() {
    this.selectedCrop = this.cropList[0];
    this.initOutputChart();
    // this.initManureChart();
    this.getYieldPrediction();
  },
  methods: {
    getYieldPrediction() {
      let params = {
        yield: this.selectedCrop.value,
        type: this.selectedCrop.code
      };
      yieldPrediction(params)
        .then((res) => {
          console.log(res);
          this.baseList = res.baseList || [];
          this.addList = res.addList || [];
          this.totalList = res.totalList || [];
          if (this.totalList.length) {
            this.initManureChart();
          }
        })
        .catch((error) => {});
    },
    cropSelect(val) {
      let selObj = null;
      this.cropList.forEach((item) => {
        if (item.code == val) {
          selObj = item;
        }
      });
      this.selectedCrop = selObj;
      this.initOutputChart();
      this.getYieldPrediction();
    },
    openYieldSetting() {
      this.yieldDg = true;
    },
    yieldInput(val) {
      let selObj = this.selectedCrop;
      if (!val || val < 0) {
        val = 1;
      } else if (val > selObj.maxValue) {
        val = selObj.maxValue;
      }
      this.selectedCrop.value = parseFloat(val || 0);
    },
    confirmYield() {
      this.yieldDg = false;
      this.getYieldPrediction();
      this.initOutputChart();
    },
    initOutputChart() {
      this.chartOutput = this.chartOutput || echarts.init(this.$refs.outputChart);
      let option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '50%'],
            startAngle: 225,
            endAngle: -45,
            min: this.selectedCrop.minValue,
            max: this.selectedCrop.maxValue,
            splitNumber: 5,
            clockwise: true,
            itemStyle: {
              color: '#FA8F00'
            },
            progress: {
              show: true,
              width: 10,
              overlap: false,
              roundCap: true,
              clip: false
            },
            pointer: {
              show: false
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 20
              }
            },
            axisTick: {
              distance: 15,
              splitNumber: 5,
              lineStyle: {
                width: 1,
                color: '#999'
              }
            },
            splitLine: {
              distance: 14,
              length: 10,
              show: false,
              lineStyle: {
                width: 2,
                color: '#FA8F00'
              }
            },
            axisLabel: {
              distance: 25,
              color: '#FA8F00',
              fontSize: 12,
              show: true,
              formatter: function (value) {
                if (value === 0) {
                  return '';
                }
                return value + '';
              }
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              offsetCenter: [0, '0%'],
              formatter: function (value) {
                let arr = [`{emptyH|}`, `{bg|}`, `{valStyle|${value}}`, `{rotate|公斤/亩}`];
                return arr.join('\n');
              },
              rich: {
                emptyH: {
                  height: 10
                },
                bg: {
                  backgroundColor: {
                    image: '/static/center_circle_bg.png'
                  },
                  width: 110,
                  height: 110,
                  borderRadius: 110
                },
                valStyle: {
                  color: '#333333',
                  fontSize: 30,
                  lineHeight: 10,
                  width: 58,
                  fontWeight: 500,
                  padding: [0, 0, 50, 5]
                },
                rotate: {
                  // backgroundColor: 'rgba(2,185,143,0.1)',
                  width: 58,
                  height: 16,
                  borderRadius: 4,
                  fontSize: 12,
                  color: '#C1C1C1',
                  padding: [0, 0, 50, 0]
                }
              }
            },
            data: [
              {
                value: this.selectedCrop.value
              }
            ]
          }
        ]
      };
      this.chartOutput && this.chartOutput.setOption(option);
    },
    initManureChart() {
      let xAxisData = [],
        seriesData = [];
      this.totalList.forEach((item) => {
        xAxisData.push(item.name);
        seriesData.push(parseFloat(item.consumption || 0).toFixed(2));
      });
      this.manureChart = this.manureChart || echarts.init(this.$refs.manureChart);
      let colors = ['#65789B', '#FFBA00', '#02B98F', '#5B8FF9', '#FF7373', '#9B78FA'];
      let option = {
        // xAxis: {
        //   type: 'category',
        //   data: ['Mon', 'Tue', 'Wed']
        // },

        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: xAxisData,
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(44,53,66,0.65)'
            }
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: '#A0A4AA'
            }
          }
        },

        yAxis: {
          type: 'value'
        },
        color: colors,
        series: [
          {
            data: seriesData,
            type: 'bar',
            itemStyle: {
              color: function (params) {
                //通过返回值的下标一一对应将颜色赋给柱子上
                return colors[params.dataIndex];
              }
            }
          }
        ]
      };
      this.manureChart && this.manureChart.setOption(option);
    }
  }
};
</script>
<style scoped lang="scss">
.page-container {
  margin: 10px 20px 0;
  background-color: #ffffff;
}
.left-item {
  background: #f8f8f9;
  margin-bottom: 20px;
  padding: 11px 0;
  position: relative;
  height: 322px;
  display: flex;
  align-items: center;
  justify-content: center;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    position: absolute;
    top: 20px;
    left: 0;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2c3542;
    }
    .el-select {
      width: 120px;
    }
  }

  .unit-tip {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2c3542;
    position: absolute;
    left: 400px;
    top: 24px;
  }
  .chart-wrap {
    background: #ffffff;
    width: 300px;
    height: 300px;
    border-radius: 300px;
    position: relative;
    .output-chart {
      width: 300px;
      height: 300px;
    }
    .setting-btn {
      width: 120px;
      height: 32px;
      background: #f2f3f5;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7e88a8;
      cursor: pointer;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .manure-chart-wrap {
    .manure-chart {
      width: 400px;
      height: 300px;
    }
  }
}

.right {
  .el-step__title {
    color: #313746;
  }
  .step-item {
    width: 100%;
    height: 60px;
    background: #f7f8fa;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    color: #303133;
    .label,
    .value {
      border-right: 1px solid #ebedf4;
      width: 50%;
      box-sizing: border-box;
      text-align: center;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .value {
      border-right: none;
    }
  }
}
.yield-wrap {
  text-align: center;
  .el-input {
    width: 200px;
  }
}
</style>
